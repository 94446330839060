@use 'config' as *;
// --- 👆 update this path if you're importing CodyFrame as npm module
// e.g., '../../../node_modules/codyframe/main/scss/config'

.icon {
  --size: 1em;
  font-size: var(--size);
  height: 1em;
  width: 1em;
  display: inline-block;
  color: inherit;
  fill: currentColor;
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
  vertical-align: middle;
  object-fit: contain;
}

.icon--3xs {
  --size: 0.25em;
}

.icon--2xs {
  --size: 0.33em;
}

.icon--xs {
  --size: 0.5em;
}

.icon--sm {
  --size: 0.75em;
}

.icon--md {
  --size: 1em;
}

.icon--lg {
  --size: 1.25em;
}

.icon--xl {
  --size: 1.5em;
}

.icon--2xl {
  --size: 2em;
}

.icon--3xl {
  --size: 3em;
}

/* rotate the icon infinitely */
.icon--is-spinning { 
  animation: icon-spin 1s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* SVG symbols - enable icon color corrections */
.icon use {
  color: inherit;
  fill: currentColor;
}