/*! purgecss start ignore */
*, *::after, *::before {
  box-sizing: border-box;
}

* {
  font: inherit;
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  background-color: var(--color-bg, white);
}

ul, ol {
  padding-top: 0;
  ul, ol {
    padding-top: 1em;
  }
}
ul {
  list-style: none;
  & > li::before {
    content: '•';
    position: absolute;
    left: -0.9em;
    top: -0.25em;
    font-size: 1.5em;
  }

  & > li > ul {
    padding-top: 1em;
    & > li::before {
      content: '◆';
      left: -1.2em;
      top: -0.15em;
      font-size: 1em;
    }
  }
}
ol {
  li::marker {
    font-weight: bold;
  }
}

ol, ul {
  font-size: var(--text-base);

  li {
    position: relative;
    margin-bottom: var(--space-sm);
  }
}
ol li {
  margin-left: 1.15em;
}
ul li {
  margin-left: 1.35em;
}

blockquote, q {
  quotes: none;
}

mark {
  background-color: transparent;
  color: inherit;
}

button, input, textarea, select, .reset {
  background-color: transparent;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
}

a {
  display: inline;
  color: var(--color-primary);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

textarea {
  resize: vertical;
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, video, svg {
  max-width: 100%;
  display: block;
}

[data-theme] {
  color: var(--color-contrast-high, hsl(240, 5%, 82%));
}