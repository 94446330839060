
@use "../scss/style";
@use "@/scss/config" as *;

:root {
  color-scheme: light dark;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  display: block;
  width: 100%;
  height: 100%;
}
body {
  display: block;
  width: 100%;
  min-height: 100%;
  background-color: var(--color-bg-dark);
}
main {
  display: block;
  width: 100%;
  min-height: 100%;
}

body {
  font-size: 0.8rem;
}

next-route-announcer {
  position: absolute;
  top: 0;
  left: 0;
}

img {
  height: auto;
  /* Stops showing the alt text if the image doesn't load */
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

button {
  user-select: none;
}

.text-button {
  display: inline;
  border: 3px solid white;
  border-radius: 1vh;
  padding: 0.5vh 2vh;
  margin-bottom: 3vh;

  font-size: 5vh;
  color: white;
  cursor: pointer;

  background-color: rgba(0, 0, 0, 0.8);
  transition: background-color 0.1s ease-in-out;
}
.text-button:hover {
  background-color: rgba(0, 0, 0, 1);
  transition: background-color 0.1s ease-in-out;
}
.centre-text {
  display: block;
  text-align: center;
}

.highlight-shadow, .shadow {
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.4));
}
.highlight-shadow:hover {
  filter: drop-shadow(6px 6px 12px rgba(255, 255, 255, 0.1));
}

/**
 * Standard elements.
 */
.gradient-background {
  background-color: var(--color-bg);
  background-image: linear-gradient(
      to bottom right,
      var(--color-bg-dark),
      var(--color-bg-light)
  );
}

.toc {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 1em;
  padding: 0.6em 1.8em 0 1em;
  font-size: var(--text-base);

  li {
    margin-left: 1em;
  }
}

.list {
  margin-top: var(--space-md);

  & > p {
    color: var(--color-contrast-higher);
    font-size: var(--text-md);
    font-weight: bold;
    margin-bottom: var(--space-xs);
  }

  li {
    padding-right: var(--space-xs);

    @include max-width(md) {
      margin-left: 1em;
    }
  }
}

figure {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  img {
    max-width: 600px;
  }

  .graph {
    width: 100%;
    height: 24rem;
    svg {
      overflow: hidden;
      border-radius: var(--space-sm);
    }
  }

  img.xs {
    max-width: none;
    max-height: 6rem;
  }

  img.small {
    max-width: none;
    max-height: 16rem;
  }

  img.medium {
    max-width: none;
    max-height: 24rem;
  }

  img.hero, img.large {
    max-width: none;
    max-height: 30rem;
  }

  table {
    border-radius: 1em;
    overflow: hidden;
    border-collapse: collapse;
    border-spacing: 0;

    thead {
      background-color: rgba(0, 0, 0, 0.5);
      td {
        padding: var(--space-2xs) var(--space-md);
      }
    }
    tbody {
      background-color: rgba(0, 0, 0, 0.3);
    }
    th {
      color: var(--color-contrast-higher);
      font-weight: bold;
      padding: var(--space-xs) var(--space-md);
    }
    td {
      padding: var(--space-2xs) var(--space-md);
    }
    td, th {
      font-size: var(--text-base);
      text-align: left;
      vertical-align: top;
    }

    &.border-table {
      border-radius: unset;
      td, th {
        border: 1px solid var(--color-contrast-low);
      }
    }
  }

  &.video_figure {
    position: relative;
    padding-bottom: 56.25%; // Default for 16:9 aspect ratio
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}


form, .form-like {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;

  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    font-size: var(--text-base);
    margin-top: var(--space-xs);
  }

  input {
    box-shadow: var(--shadow-md);
  }

  input[type=text],
  input[type=password],
  input[type=email] {
    height: 2em;
  }

  input[type=text],
  input[type=password],
  input[type=email],
  textarea {
    display: flex;
    font-size: var(--text-base);
    padding: 1.1em 0.75em;
    margin: var(--space-3xs) 0 var(--space-2xs);
    width: 100%;
    border-radius: 0.5em;

    color: var(--color-contrast-higher);
    background-color: var(--color-contrast-lower);

    &:focus-visible {
      border-color: transparent;
      outline: 2px solid var(--color-rosette-dark);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  input[type=checkbox] {
    display: flex;
    position: relative;
    font-size: var(--text-base);
    height: 1.2em;
    width: 1.2em;
    border-radius: 0.25em;
    border: 1px solid var(--color-contrast-high);
    cursor: pointer;

    &:checked {
      border: none;
      background-color: var(--color-rosette-dark);
    }

    &:focus-visible {
      border-color: transparent;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  p {
    margin: 0 0 var(--space-md);
  }

  p.value {
    font-size: var(--text-base);
    min-height: 1em;
    line-height: 1em;
    margin: var(--space-sm) 0;
    margin-top: var(--space-2xs);

    a.change-link {
      display: inline-block;
      font-size: var(--text-base);
      margin-left: var(--space-2xs);
      user-select: none;
    }
  }
  p.error, p.success {
    font-size: var(--text-base);
    min-height: 1em;
    line-height: 1em;
    margin: var(--space-3xs) 0 var(--space-2xs);
  }
  p.error {
    color: var(--color-error-darker);
  }

  button[type=submit], .button, a.button-like {
    display: block;
    width: 100%;
    font-weight: bold;
    text-align: center;

    font-size: var(--text-md);
    color: var(--color-accent-darkest);
    background-color: var(--color-accent);
    background-image: linear-gradient(
                    180deg,
                    var(--color-accent) 0%,
                    var(--color-accent-below) 100%,
    );
    border-bottom: var(--space-3xs) solid var(--color-accent-darker);
    box-shadow: var(--shadow-md);
    padding: var(--space-xs) var(--space-sm);
    border-radius: var(--space-2xs);
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: var(--color-accent-lightest);
      background-image: none;
    }
    &:active {
      background-image: linear-gradient(
                      180deg,
                      var(--color-accent) 0%,
                      var(--color-accent-dark) 100%,
      );
    }
    &:disabled {
      color: var(--color-contrast-low);
      background-color: var(--color-contrast-medium);
      border-bottom-color: var(--color-contrast-low);
      background-image: none;
      cursor: not-allowed;
    }
  }
}

.checkbox-container,
.input-container {
  margin: var(--space-3xs) 0 var(--space-2xs);

  .checkbox-container,
  .input-container,
  input {
    margin: 0;
  }
}

.section {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: var(--space-sm);
  width: 100%;
  overflow: hidden;

  padding: var(--space-xs);
  @include min-width(sm) {
    padding: var(--space-sm);
  }

  ul, ol {
    margin-bottom: -0.8em;
    li {
      margin-left: 1.4em;
    }
  }
}
